import { Modal } from 'bootstrap';
import Masonry from 'masonry-layout';
import WebFontLoader from 'webfontloader';

export default () => {

    'use strict';

    const get = data_js =>
        document.querySelector("[data-js=\"" + data_js + "\"]");

    const toggle_bottom_bar = () => {
        const button_wrapper = get('footer-menu-bar');
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            button_wrapper.style.display = 'block';
        } else {
            button_wrapper.style.display = 'none';
        }
    };

    const form_success = (form, event) => {
        const [_data, _status, xhr] = event.detail;
        const new_body = document.createElement('modal-body');
        new_body.innerText = xhr.responseText;
        get('success-modal').querySelector('modal-body').replaceWith(new_body);
        new Modal(get('success-modal')).show();
        form.reset();
    };

    const setup_form_responses = () => {
        document.querySelectorAll('form').forEach(form => {
            form.addEventListener('ajax:success', event =>
                form_success(form, event));
            form.addEventListener('ajax:error', () =>
                new Modal(get('failure-modal')).show());
        });
    };

    const scroll_to_path = () => {
        const bookmark = window.location.pathname;
        if (get(bookmark)) { get(bookmark).scrollIntoView(); }
    };

    const initialize_masonry = () => {
        document.querySelectorAll('.wf-active [data-js="masonry"]').forEach(masonry => {
            return new Masonry(masonry, {
                percentPosition: true
            });
        });
    };

    const load_web_fonts = () => {
        return WebFontLoader.load({
            google: {
                families: ['Lemon', 'Comfortaa', 'Francois One']
            },
            active: initialize_masonry
        });
    };

    const on_load_handlers = [
        setup_form_responses,
        toggle_bottom_bar,
        scroll_to_path,
        load_web_fonts
    ];

    on_load_handlers.forEach(handler =>
        document.addEventListener('turbolinks:load', handler));

    window.addEventListener('scroll', toggle_bottom_bar);

};